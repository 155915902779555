import { buildMediaQueries, getImage, ImageQuery } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { metadata } from '../../../data/metadata/images';
import { colors } from '../../../modules/colors';
import { CallToActionButton } from '../../button';
import { JobsLink } from '../../links';
import { ResponsivePrimaryText, ResponsiveSectionHeader } from '../../typography';

const SectionStyle = createOwnUpStyle({
  display: 'flex',
  alignItems: 'center',
  margin: '50px 200px',
  variants: {
    mediumAndDown: {
      flexDirection: 'column',
      alignItems: 'center',
      margin: '0 50px'
    }
  }
});

const Section = createOwnUpComponent('section', SectionStyle);

const LeftWrapperStyle = createOwnUpStyle({
  display: 'flex',
  width: '100%',
  '& img': {
    borderRadius: '50%'
  },
  variants: {
    mediumAndDown: {
      paddingBottom: 50
    }
  }
});

const LeftWrapper = createOwnUpComponent(OwnUpBox, LeftWrapperStyle);

const ImageWrapperStyle = createOwnUpStyle({
  width: '100%',
  margin: 'auto',
  textAlign: 'center'
});

const ImageWrapper = createOwnUpComponent(OwnUpBox, ImageWrapperStyle);

const RightWrapperStyle = createOwnUpStyle({
  width: '100%'
});

const RightWrapper = createOwnUpComponent(OwnUpBox, RightWrapperStyle);

const HeaderStyle = createOwnUpStyle({
  ...fonts.TIEMPOS_HEADLINE_MEDIUM,
  color: colors.PRIMARY,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 30,
  lineHeight: '110%'
});

const Header = createOwnUpComponent(ResponsiveSectionHeader, HeaderStyle);

const TextWrapperStyle = createOwnUpStyle({
  padding: '15px 0',
  variants: {
    mediumAndDown: {
      padding: '30px 0'
    }
  }
});

const TextWrapper = createOwnUpComponent(OwnUpBox, TextWrapperStyle);

const LinkWrapper = styled('div')`
  display: flex;
  justify-content: center;
  ${buildMediaQueries('large', {
    justifyContent: 'flex-start'
  })}
`;

const JobsButton = ({ callToAction }: { callToAction: string }) => (
  <LinkWrapper>
    <JobsLink>
      <CallToActionButton>{callToAction}</CallToActionButton>
    </JobsLink>
  </LinkWrapper>
);

export const imgStyle = {
  maxWidth: 250,
  margin: 'auto',
  position: 'relative' as const,
  borderRadius: '50%'
};

export const BinocularsImage = ({ imageData }: { imageData: ImageQuery }) =>
  getImage(imageData, 'icon-binoculars.png', metadata, imgStyle);

export const WorkWithUs = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Section aria-label="work-with-us">
      <LeftWrapper>
        <ImageWrapper>{children}</ImageWrapper>
      </LeftWrapper>
      <RightWrapper>
        <Header>Want to work with us?</Header>
        <TextWrapper>
          <ResponsivePrimaryText>
            We think we have a pretty great team (if we do say so ourselves), and we’re always on
            the lookout for talented folks who want to do things a little differently. Check out our
            Careers page for current openings.
          </ResponsivePrimaryText>
        </TextWrapper>
        <JobsButton callToAction="VIEW POSTINGS" />
      </RightWrapper>
    </Section>
  );
};
